 
 @tailwind base;
 @tailwind components;
 @tailwind utilities; 
 
 :root {
  --main-color: rgba(93, 95, 239, 1);
  --hover-color: rgb(51, 52, 143);
  --second-color: #ff5722; 
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Cairo', sans-serif;
  box-sizing: border-box;
  margin: 0px;
  overflow-x: hidden;
  font-weight: bold;
  background:  #f5f7f6;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
table td{
  font-weight: 400;
}
.container {
  /* padding-left: 15px;
  padding-right: 15px; */
  margin-left: auto;
  margin-right: auto;
}
.operation img{
  cursor: pointer;
}
/* .button{
  background-color: var(--second_color) ;
  color: white;
  padding: 7px 15px 0px;
  border-radius: 10px;
  transition: .3s;
}
.button:hover{
  background-color: var(--main_color) ;
  transform: scale(.98);
} */
/* Small */
@media (min-width: 768px) {
  .container {
      width: 750px;
  }
}
/* Medium */
@media (min-width: 992px) {
  .container {
      width: 970px;
  }
}
/* Large */
@media (min-width: 1200px) {
  .container {
      width: 1150px;
  }
}
.flexBetween{
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 980px) {
  .flexBetween{
    display: block;
  }
}
@media (max-width: 980px) {
  .buttonStyle{
    padding: 7px 7px;
  }
  .link span{
    width: 130px;
  }
} 



@media (max-width: 988px) {
  h1{
    font-size: 20px;
  }
}