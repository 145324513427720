.theme-switcher-with-icon {
    position: absolute;
    top: 50%;
}

.theme-switcher-with-icon .color-input {
    cursor: pointer;
    border: none;
    padding: 5px;
}

.theme-switcher-with-icon .ok-button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.theme-switcher-with-icon .ok-button:hover {
    background-color:var(--main-color); 
}

