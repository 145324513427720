#ChatApp {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
/* sidebar Chat */
.ChatApp .SidebarChat {
  width: 30%;
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ChatApp .SidebarChat::-webkit-scrollbar {
  width: 8px;
}

.ChatApp .SidebarChat::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.ChatApp .search {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.ChatApp .search input {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #f1f1f1;
  width: 100%;
  font-family: "Cairo", sans-serif !important;
  outline: none;
  border: none;
}
.ChatApp .search input::placeholder {
  font-family: "Cairo", sans-serif !important;
}
.ChatApp .friend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  margin: 5px 10px;
}

.ChatApp .friend:hover {
  background-color: rgba(93, 95, 239, 0.1);
}

.ChatApp .friend.active {
  background-color: var(--main-color);
  color: white;
}
.ChatApp .friend-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.ChatApp .friend-info h4 {
  margin: 0;
  color: #333;
  font-size: 14px;
}

.ChatApp .friend-info p {
  margin: 0;
  color: #666;
  font-size: 12px;
}

.ChatApp .friend.active h4 {
  color: white !important;
}
.ChatApp .friend.active p {
  color: rgba(163, 161, 255, 1) !important;
}

/* ChatWindow */


.chat-header {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background-color: var(--main-color);
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}

.chat-body {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.from-me {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.from-them {
  align-self: flex-start;
}

.message img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 10px;
}

.message .text {
  padding: 2px 15px;
  border-radius: 15px;
  max-width: 90%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 12px;
}

.from-me .text {
  background-color: #dcf8c6;
}

.from-them .text {
  background-color: #ffffff;
}

.message .timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.chat-footer {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.chat-footer input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
}

.chat-footer {
  display: flex;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
  margin-right: 10px;
}

.send-button {
  padding: 10px 20px;
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Cairo", sans-serif;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: rgba(57, 60, 209, 0.986);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  background: #fff;
  filter: blur(3px) contrast(7) hue-rotate(290deg);
  mix-blend-mode: darken;
}
.loader:before {
  content: "";
  margin: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ff0000;
  background: currentColor;
  box-shadow: -30px 0, 30px 0, 0 30px, 0 -30px;
  animation: l6 1s infinite alternate;
}
@keyframes l6 {
  90%,
  100% {
    box-shadow: -10px 0, 10px 0, 0 10px, 0 -10px;
    transform: rotate(180deg);
  }
}
