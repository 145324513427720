.css-1qb993p-MuiDataGrid-columnHeaderTitle,
.css-1w88v09-MuiDataGrid-root .MuiDataGrid-cell--textLeft{
    font-family: 'Cairo', sans-serif;
}
.dialog-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom:  1px solid rgba(151, 151, 151, 1);

  }
  
  .dialog-title {
    padding: 0;
    font-weight: bold;
  }
  
  .dialog-actions {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
  
  .cancel-button {
    font-family: 'Cairo', sans-serif !important;
    color: #ff1835 !important;
    border: 1px solid #ff1835 !important;
    margin-right: 10px;
  }

  .save-button {
    border: 1px solid  !important;
    font-family: 'Cairo', sans-serif !important;
    gap: 10px !important;

  }
  .css-knqc4i-MuiDialogActions-root {
    padding: 20px !important;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    border: 1px solid black !important;
    padding: 5px !important;
  }
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 5px 24px !important;
  }
  .css-17fj50n-MuiDataGrid-root .MuiDataGrid-cell--textLeft{
    font-family: 'Cairo', sans-serif !important;
  }
.table-header {
  background-color: rgba(238, 234, 245, 1);
  text-align: center;
}

.table-cell {
  width: 100px; 
  text-align: center; 
}

.selected-row {
  background-color: rgba(93, 95, 239, 0.6);
}

.css-1ygcj2i-MuiTableCell-root{
  padding: 0px !important;
  text-align: center !important;
  font-family: 'Cairo', sans-serif !important;
  width: 100px !important;

}
.MuiDataGrid-root {
  overflow-x: auto !important;
}
.div-open-modal{
  position: fixed;
  bottom: 20px;
  left: 45%; 
  width: 70%;
  transform: translateX(-50% );
  background-color: white;
  border: 1px solid rgba(151, 151, 151, 1) !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 100; 
}
.all-button ,.all-button div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media (max-width: 988px) {
  .all-button ,.all-button div{
    flex-direction: column;
  }
}
.open-modal-button {
  color: white;
  background-color: var(--main-color);
  border: none;
  font-family: 'Cairo', sans-serif;
  padding: 5px 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.open-modal-button img{
  width: 20px;
}
.export-button{
  background-color: white;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 5px 40px;
  border-radius: 5px;
  margin-left: 10px;
  font-family: 'Cairo', sans-serif;
  cursor: pointer;
}
.close-modal-button {
  background: white; 
  border: 1px solid rgba(255, 24, 53, 1);
  color: rgba(255, 24, 53, 1);
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  margin-top: 10px; 
  font-family: 'Cairo', sans-serif;
  cursor: pointer;
}
.css-12wafvu{
  width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
