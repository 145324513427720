.invoices-container h2 {
   margin: 0px ;
   padding: 0px;
}
.containerInput{
    background-color:  rgba(93, 95, 239, 0.1);
    padding: 10px;
    border-radius: 10px;
}
.input-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.input-group label {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}

.input-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.table-container {
    padding: 20px;
    border-radius: 8px;
}

.scrollable-table {
    max-height: 400px; /* Adjust height as needed */
    overflow-y: auto;
}

.invoices-table {
    width: 100%;
    border-collapse: collapse;
}

.invoices-table th,
.invoices-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.pagination {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination .active {
    background-color:var(--main-color);
    color: white;
}


          /* Base style for body */
body {
    font-family: 'Amiri', sans-serif;
    direction: rtl;
    text-align: right;
    margin: 0;
    padding: 0;
}

/* Container with margin */
.container {
    margin: 1rem;
}

/* Table styling */
table {
    width: 100%; 
    overflow-x: auto;

    margin-bottom: 1.25rem; /* 20px */
    font-size: 0.875rem; /* 14px */
}

/* Table header and cells styling */
th, td {
    border: 1px solid #ddd; /* Lighter border color */
    padding: 0.2rem; /* 10px */
    text-align: right;
}

/* Table header background and font weight */
th {
    background-color: #f9fafb; /* Light gray for better contrast */
    font-weight: 600;
}

/* Styling for image container */
.file-list img {
    width: 250px;
    margin: 0.5rem;
    border-radius: 0.375rem; /* Rounded corners */
}

/* PDF link styling */
.file-item {
    margin: 0.2rem;
    text-decoration: none;
    color: #3182ce; /* Tailwind blue-500 */
}

.file-item:hover {
    text-decoration: underline;
}