.invoice-page {
    padding: 20px;
    background-color: #fff;
    margin: 20px;
    border-radius: 10px;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.invoice-page .action-buttons {
    display: flex;
    gap: 10px;
}

.invoice-page .action-button {
    font-family: 'Cairo', sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 5px 16px;
    cursor: pointer;
}

.invoice-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
}

.invoice-page .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.invoice-page input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Cairo', sans-serif !important;
}

.invoice-page .checkboxes {
    display: flex;
    gap: 20px;
}

.invoice-page .checkbox-group {
    display: flex;
    gap: 10px;
}

.invoice-page .checkbox-group.single {
    flex: 1;
    flex-direction: column;
}

.invoice-page .checkbox-group.double {
    flex: 1; 
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.invoice-page .add-item-button {
    background-color: var(--main-color);
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: fit-content;
    font-family: 'Cairo', sans-serif;
}

.invoice-page .invoice-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.invoice-page .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-width: 600px; 
}

.invoice-table th,
.invoice-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
}

.invoice-table input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox-group input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.checkbox-group label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 45px;
    font-family: 'Cairo', sans-serif;
    font-size: 16px;
    user-select: none;
}

.checkbox-group label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    background-color: white;
    border: 2px solid  var(--main-color) !important;
    border-radius: 6px;
    border: 2px solid #fff;
    transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-group input[type="checkbox"]:checked + label::before {
    background-color: #fff;
    border-color: var(--main-color);
}

.checkbox-group label:hover::before {
    background-color: rgba(77, 44, 166, 0.26);
}

.checkbox-group input[type="checkbox"]:checked + label::after {
    content: "✔";
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: var(--main-color);
}

/* style modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-close {
    cursor: pointer;
    font-size: 20px;
}

.modal-body {
    margin-top: 20px;
}

.modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.modal-footer .action-button {
    margin: 0;
}
.modal-overlay .CircularProgressbar {
    width: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.icondeleteModal{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    border : none;
    font-size: 30px;
    cursor: pointer;
}
.btn-colose{
    font-size: 20px !important;
    left: 5px !important;
    top: 5px !important;
}
.message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}
.success {
    background-color: #d4edda;
    color: #155724;
}

.error {
    background-color: #f8d7da;
    color: #721c24;
}

@media (max-width: 980px) {
    .form-row {
        flex-wrap: wrap;
    }
    .invoice-page .invoice-table-container {
        overflow-x: scroll;
    }
    .invoice-header,
    .invoice-page .checkboxes{
        flex-direction: column;
    }
    .action-button{
        margin-top: 0px !important;
    }
    
}

.add-account-container {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
}
.profile-picture-container {
    margin-bottom: 20px;
}

.profile-picture {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: var(--main-color);
    color: rgba(255, 255, 255, 0.74);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}

.profile-picture input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    justify-content: space-between;
}

.form-group div {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

.form-group label {
    flex: 1;
    margin-bottom: 5px; 
    font-weight: bold;
    font-size: 14px;
}

.form-group input, .form-group select {
    flex: 2;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: 'Cairo', sans-serif;

}

select {
    appearance: none; 
    background-color: #ffffff;
    cursor: pointer;
    padding-right: 20px;
    position: relative;
}

.add-account-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-family: 'Cairo', sans-serif;
    margin-top: 50px;
}

.add-account-button:hover {
    background-color: var(--hover-color);
}

@media (max-width: 980px) {
    .form-group {
       flex-direction: column;
    }
}  


.modal-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modal-content {
    background-color: white !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px; 
    width: 100%; 
}
.div-open-modal{
    position: fixed;
    bottom: 20px;
    left: 50%; 
    width: 50%;
    transform: translateX(-50% );
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 1) !important;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 100; 
}
.open-modal-button {
    color: white;
    background-color: var(--main-color);
    border: none;
    font-family: 'Cairo', sans-serif;
    padding: 5px 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.open-modal-button img{
    width: 20px;
}
.export-button{
    background-color: white;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    padding: 5px 40px;
    border-radius: 5px;
    margin-left: 10px;
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}
.close-modal-button {
    background: white; 
    border: 1px solid rgba(255, 24, 53, 1);
    color: rgba(255, 24, 53, 1);
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    margin-top: 10px; 
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}
.success-icon {
    color: green;
    margin-right: 8px; 
    font-size: 40px;
}