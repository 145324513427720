@tailwind base;
@tailwind components;
@tailwind utilities;  

body {
  font-weight: 700 !important; /* Ensure text in the entire body is bold */
}

table th, 
table td {
  font-weight: 700 ! important; /* Bold font for table headers and cells */
}

