.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    background-color: white;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 20px;
    padding-bottom: 10px;
}

#myInput {
    background-image: url('../img/XMLID 223.png');
    background-position: calc(100% - 10px) 12px; 
    background-repeat: no-repeat;
    background-color: rgba(249, 250, 251, 1);
    border-radius: 10px;
    width: 300px;
    font-size: 14px;
    padding: 12px 40px 12px 20px; 
    border: none;
    outline: none;
    margin-bottom: 12px;
    direction: rtl;
    font-family: 'Cairo', sans-serif;
    
}

.search-container {
    display: flex;
    align-items: center;
}

.actions {
    display: flex;
    align-items: center;
}

.language-selector, .user-info {
    position: relative;
    margin-left: 20px;
}
.language-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 30px;
}
.user-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    
}
.user-info .userName{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-info h4{
    padding: 0px;
    margin: 0px 0px 0px 10px;
    font-size: 15px;
}
.user-info p{
    margin: 0px;
    font-size: 10px;
    color: rgba(115, 119, 145, 1);
}
.user-details{
    margin-right: 10px;

}
.language-button{
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px ; 

}
.language-button span{
    margin-left: 26px;
}
.user-image{
    width: 50px;
    height: 50px;
    margin-left: 5px;
    border-radius: 10px;
}
.language-flag {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.chevron-icon {
    transition: transform 0.3s;
}

.chevron-icon.open {
    transform: rotate(180deg);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    list-style: none;
    margin: 0;
    display: none;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
}

.dropdown-menu li {
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.language-selector .dropdown-menu,
.user-info .dropdown-menu {
    display: block;
    opacity: 1;
    transform: translateY(0);
    z-index: 999999;
}

@media (max-width: 768px) {
    .header{
        flex-direction: column-reverse;
        gap: 20px;

    }
    .search-container,#myInput{
        width: 100%;
    }
    .actions ,.user-info{
        width: 100%;
    }
}
