.modal-body .group {
  text-align: start;
}
.modal-body .group label {
  text-align: start !important;
}
.modal-body .group .action-button {
  margin-top: 20px !important;
}
.modal-footer {
  gap: 20px !important;
}

.toggle-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  accent-color: #4caf50; /* Change to desired color */
}

.toggle-label {
  font-size: 0.875rem; /* Tailwind class: text-sm */
  color: #333; /* Tailwind class: text-gray-700 */
}
 


