.billingManagement #two{
    background-color: white;
    color: var(--main-color);
    backdrop-filter: "blur(5.5px)";
    border: "1px solid rgba( 255, 255, 255, 0.18 )";
}
.tabs{
    margin: 20px;
    border-radius: 10px;
    background-color: white;
}

.react-tabs__tab-list{
    background-color: rgba(93, 95, 239, 0.1);
    border-color: var(--main-color);
    padding: 20px 20px 0px 20px;
    border-radius: 10px 10px 0px 0px;
    direction: ltr !important;
}
.react-tabs__tab--selected {
    border-color: #68b7ffde;
    color: var(--main-color);
}
.react-tabs__tab-panel--selected{
    padding: 20px;
}