.Home #four{
    background-color: white;
    color: var(--main-color);
    backdrop-filter: "blur(5.5px)";
    border: "1px solid rgba( 255, 255, 255, 0.18 )";
    border-radius: 10px;
    transform: translateX(-5px);

}
.Home #four .item{
    color: var(--main-color);
}