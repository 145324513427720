.cards{
    padding: 0 20px 20px 20px;
    margin: 20px;
    border-radius: 10px;
    background-color: white;
}
.cards-container {
    display: flex;
    justify-content: space-between;
}
.card {
    flex: 1 1 22%; 
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    background-color:  #ffe2e5;
    color: rgba(21, 29, 72, 1);
    justify-content: space-between;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05); 
}

.card-img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

.card-number {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-right: 10px;
}

.card-title {
    font-size: 18px;
    font-weight: 500;
    color:rgba(66, 81, 102, 1);
    
}

.card-icon{
    padding: 10px 13px;
    color: white;
    border-radius: 50% ;
    font-size: 19px;
    background-color: #fa5a7d;   
}
@media (max-width: 1200px) {
    .card {
        flex: 1 1 45%; 
    }
}

@media (max-width: 768px) {
    .card {
        flex: 1 1 100%; /* العرض لكل بطاقة سيكون 100% في الشاشات الصغيرة */
    }
    .cards-container{
        flex-direction: column;
    }
}
