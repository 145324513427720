
.TabelCategories.checkbox-group {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}
.css-1869usk-MuiFormControl-root{
    width: 100%;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input 
{
    padding: 10px 0px;
}
 .checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-checkbox {
    appearance: none;
    width: 30px !important;
    height: 30px !important;
    border: 2px solid rgba(76, 175, 79, 1);
    border-radius: 4px; 
    background-color: #fff; 
    position: relative; 
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}
.custom-checkbox:checked {
    background-color: white; 
    border-color:  var(--main-color); 
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: var(--main-color);
}
