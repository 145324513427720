.body_container {
  flex: 1;
  width: 60%;
}

.sidebar_container {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  z-index: 50;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.sidebar_container::-webkit-scrollbar {
  width: 8px;
}

.sidebar_container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: start;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  background: var(--main-color);
  color: white;
  backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  direction: rtl;
  transition: width 0.3s ease;
}
.sidebar-contant {
  width: 100%;
}
.sidebar-contant .lines_icon {
  width: fit-content;
}
.lines_icon {
  display: flex;
  align-self: flex-end;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.logoICon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profile > img {
  width: 100%;
}

.groups {
  display: flex;
  width: 100%;
}

.group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1px 0px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 5px;
  color: white;
  font-size: 13px;
  height: 35px;
  font-family: "Cairo", sans-serif !important;
  width: 98%;
  z-index: 99999999;
  padding: 3px 0px;
}
.activeLink {
  background-color: #ffff;
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 5px;
  color: var(--main-color);
  font-size: 13px;
  height: 35px;
  font-family: "Cairo", sans-serif !important;
  width: 98%;
  z-index: 99999999;
  padding: 3px 0px;
}

.item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateX(-5px);
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateX(-5px);
  border-radius: 10px;
}

.menu-item .item:hover {
  background-color: transparent;
}

.icon {
  margin-right: 0.5rem;
  font-size: 22px;
  margin-left: 16px;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateX(-5px);
  border-radius: 0px 0px 10px 10px;
  margin-top: -10px;
}

.sub-menu .item:first-child {
  margin-top: 10px;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.menu-item i {
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
}

.user-card {
  background-color: white;
  color: var(--main-color);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  margin-top: auto;
  transition: opacity 0.3s ease;
}

.user-card-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-card-info h4,
.user-card-info p {
  margin: 0;
}

.user-card-info p {
  font-size: 13px;
}

.logout-button {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Cairo", sans-serif;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.logout-button:hover {
  background-color: var(--hover-color);
}

.sidebar[data-open="false"] .user-card {
  opacity: 0;
  pointer-events: none;
}
.sidebar[data-open="false"] .user-card-img {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}
