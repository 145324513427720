.provinces-container {
    padding: 20px;
    background-color: #f9f9f9;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-section h2 {
    color: black;
    font-family: 'Cairo', sans-serif;
    font-size: 24px;
}

.add-button {
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Cairo', sans-serif;
    font-size: 16px;
}

.add-button:hover {
    background-color: rgba(57, 60, 209, 0.986);
}
.scrollable-table {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 400px;
    -webkit-overflow-scrolling: touch;
}

.provinces-table {
    border-collapse: collapse;
    font-family: 'Cairo', sans-serif;
    width: 100%;
    overflow-x: auto;
}

.provinces-table th,
.provinces-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.provinces-table thead th {
    background-color: var(--main-color);
    color: white;
    font-weight: bold;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.provinces-table tbody td {
    background-color: white;
    color: black;
}

.provinces-table tbody tr:hover {
    background-color: #f1f1f1;
}

.provinces-container {
    padding: 20px;
    background-color: #f9f9f9;
}
.provinces-container .action-button{
    margin-top: 0px !important ;
    margin-left: 15px;
}
@media only screen and (max-width: 768px) {
    .scrollable-table {
        overflow-x: auto;
        max-height: none; 
        width: 100%;
    }

    .provinces-table {
        display: block; 
        width: 100%;
        overflow-x: auto;
    }

    .provinces-table th,
    .provinces-table td {
        white-space: nowrap;
    }
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-section h2 {
    color: var(--main-color);
    font-family: 'Cairo', sans-serif;
    font-size: 24px;
}

/* Add button */
.add-button {
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Cairo', sans-serif;
    font-size: 16px;
}

.add-button:hover {
    background-color: var(--main-color);
}

/* Table styling */
.provinces-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}

.provinces-table th,
.provinces-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.provinces-table thead th {
    background-color: var(--main-color);
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.provinces-table tbody td {
    background-color: white;
    color: black;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h3 {
    margin: 0;
    color:var(--main-color);
}

.modal-close {
    cursor: pointer;
    font-size: 20px;
}

.modal-body .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.modal-body .form-group {
    flex-direction: column;
    align-items: start;
    margin: 5px;
}

.modal-body label {
    display: block;
    margin-bottom: 5px;

}
.modal-body input {
    padding: 15px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 90%;
    font-family: 'Cairo', sans-serif;

}
.modal-body input::placeholder{
    font-family: 'Cairo', sans-serif;
}
.modal-footer {
    display: flex;
    justify-content: flex-start;
}

.action-button {
    border: 1px solid var(--main-color) !important;
    color: var(--main-color);
    background-color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 100px;
    font-family: 'Cairo', sans-serif;
    transition: .3s;
}

.action-button:hover {
    background-color: var(--main-color);
    color: white
}

@media (max-width: 988px) {
    .header-section,
    .modal-body .form-row {
      flex-direction: column;
      align-items: start !important;
    }
    .header-section h2 {
        font-size: 20px !important;
        margin: 0px 0px 10px 0px;
    }
    .modal-content {
        max-width: 280px !important ;
    }
    .modal-body .form-group{
        width: 100%;
        align-items: start !important;
    }
  }

