
.governorates-table {
    border-collapse: collapse;
    width: 500px;
}

.governorates-table th,
.governorates-table td {
    padding: 10px;
    border: 1px solid rgba(244, 240, 255, 1);
    text-align: center;
}
